import api from '../api';
import store from '@/store'

/**
 * @param {int} uuid
 * @param {string} first_name
 * @param {string} last_name
 * @param {string} lang
 * @param {Array} schools
 * @param {Array} classes
 * @param {string} role
 */
export default class User {
  static GRADE_INDEX = {
    '1': 'grade-1',
    '2': 'grade-2',
    '3': 'grade-3',
    '4': 'grade-4',
    '5': 'grade-5',
    '6': 'grade-6',
  };

  constructor(rawProperties) {
    this.setUser(rawProperties);
  }

  setUser(properties) {
    this.uuid = properties.uuid;
    this.first_name = properties.first_name;
    this.last_name = properties.last_name;
    this.lang = properties.lang;

    this.schools = properties.schools;
    this.classes = properties.classes;

    this.role = properties.role;
  }

  async fetchData(type, extraData = {}) {
    switch (type) {
      case 'tasks': {
        try {
          return await api
            .instance()
            .get(
              `/lesson/ramot-lessons/?class_id=${extraData.classId}&type=task`
            );
        } catch (e) {
          return false;
        }
      }
      case 'books': {
        try {
          return await api
            .instance()
            .get(
              `/lesson/ramot-lessons/?class_id=${extraData.classId}&type=book`
            );
        } catch (e) {
          return false;
        }
      }
      case 'assessment':
      case 'content': {
        try {
          return await api
            .instance()
            .getHome(
              `/collections/${store.state.librarySlug}-classes/`
            );
        } catch (e) {
          return false;
        }
      }
    }
  }

  isStudent() {
    return this.role === 'STUDENT';
  }

  isTeacher() {
    return this.role === 'TEACHER';
  }

  getUserClassGrade(classId) {
    const classObj = this.classes.find((classItem) => classItem.id == classId);
    const isArb = store.state.librarySlug === 'ramot-arb'
    const grade = classObj.grade ? classObj.grade : 1
    const gradeSlug =  store.state.librarySlug + '-' + User.GRADE_INDEX[grade];
    return gradeSlug
  }
}
