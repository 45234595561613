<template>
  <div>
    <Navbar :bannerText="bannerText" :logoUrl="logoUrl"/>
    <DigitalBooks
        :digitalbooks="digitalbooks"
        :classifications="classifications"
        :unitcontent="unitcontent"
        :primaryClassificationTitle="primaryClassificationTitle"
        :style="{
          'padding-bottom': recommended && recommended.length? null:'280px'
    }"
    />
    <MonthlyRecommended v-if="recommended && recommended.length" :recommended="recommended"/>

    <Footer/>
    <router-view></router-view>
  </div>
</template>
<script>
  import Navbar from '../components/Navbar.vue'
  import DigitalBooks from '../components/DigitalBooks.vue'
  import MonthlyRecommended from '../components/MonthlyRecommended.vue'
  import Footer from '../components/Footer.vue'
  import { mapState } from 'vuex'
  import store from '../store'
  import { MUTATION_TYPES } from '../store/types'
  import LoginError from '../components/LoginError'
  import { ASSESSMENT_KEY, ASSESSMENT_VALUE, ASSESSMENT_KEY_ARB, ASSESSMENT_VALUE_ARB  } from '@/constants.js'
  export default {
    name: 'Home',
    data () {
      return {
        navbarContent: null,
        digitalbooks: null,
        unitcontent: null,
        classifications: null,
        primaryClassificationTitle: null,
        recommended: null,
        bannerText: null
      }
    },
    components: {
      LoginError,
      Navbar,
      DigitalBooks,
      MonthlyRecommended,
      Footer
    },

  computed: {
    ...mapState({
      librarySlug: (state) => state.librarySlug,
      logoUrl: state => state.logoUrl
    }),

  },

  mounted() {
    this.getAllData();
  },

  watch: {
    librarySlug() {
      this.getAllData();
    },
  },

  methods: {
    async getAllData() {
      let resp;

      try {
        resp = await Promise.all([
          this.$api.getHome("library/" + this.librarySlug + "/"),
          this.$api.getHome(
            "library/" + this.librarySlug + "/classifications/"
          ),
          this.$api.getHome(
            "collections/" + this.librarySlug + "-digital-books/"
          ),
          this.$api.getHome("collections/" + this.librarySlug + "-classes-homepage/"),
          this.$api.getHome(
            "collections/" + this.librarySlug + "-monthly-recommendations/"
          ),
        ]);
      } catch (err) {}
      await this.$store.dispatch('setLang', this.librarySlug);
      this.classifications = this.buildClassification(
        resp[1],
        resp[0].primary_classification_key
      );
      this.bannerText = resp[0].metadata.banner_text;
      this.$store.dispatch('setLogoUrl', resp[0].logo_url)
      this.primaryClassificationTitle = resp[1].find(
        (classifications) =>
          classifications.id == resp[0].primary_classification_key
      ).title;
      this.digitalbooks = this.buildDigitalBooks(resp[2]);
      this.unitcontent = this.buildUnitContent(resp[3].node.children);
      console.log(this.unitcontent)
      this.recommended = this.buildRecommended(resp[4]);
    },
    isArb () {
        return this.$store.state.librarySlug === 'ramot-arb'
    },
    buildUnitContent(node) {
      let assessment_key = (this.isArb()) ? ASSESSMENT_KEY_ARB : ASSESSMENT_KEY
      let assessment_value = (this.isArb())?ASSESSMENT_VALUE_ARB : ASSESSMENT_VALUE
      return node.map((nodeItem) => {
        return {
          slug: nodeItem.data.slug,
          title: nodeItem.data.title,
          classes: nodeItem.entries.map((classNode) => {
            return {
              id: classNode.data.id,
              title: classNode.data.title,
              description: classNode.data.description,
              assessment: (classNode.data.classifications.findIndex(classification => classification['key'] == assessment_key && classification['value'] == assessment_value) !== -1) ? true : false,
              image: classNode.data.image,
              classifications: classNode.data.classifications,
              ...classNode.object,
            };
          }),
        };
      });
    },
    buildClassification(node, key) {
      return node.filter((classifications) => classifications.id == key)[0]
        ?.values;
    },
    buildRecommended(node) {
      return node.entries.map((recommended) => {
        return {
          ...recommended.data,
          ...recommended.object,
        };
      });
    },
    buildDigitalBooks(node) {
      return node.entries.map((bookNode) => {
        return {
          ...bookNode.data,
          ...bookNode.object,
        };
      });
    }
  },
};
</script>
<style lang="scss">

</style>