<template>
  <div class="page-wrap content-page">
    <loader style="z-index: 1;" v-if="loading"></loader>
    <div class="head-unit">
      <h1>{{ (currentRoute == 'content') ? $t('DIGITAL_UNIT_CONTENT') : $t('ASSESSMENTS') }}</h1>
    </div>
    <div class="class-selected">
      <p> {{ (currentRoute == 'content') ? $t('SHOW_TASKS_FOR') : $t('SHOW_ASSESSMENTS_FOR') }}</p>
      <div class="select-wrap">
        <select v-model="currentGrade" name="classes" id="classes" class="select-class">
          <option :value="grade.slug" v-for="(grade, i) in content" :key="i">
            {{ grade.title.toString() }}
          </option>
        </select>
      </div>
    </div>
    <div class="selected-class d-flex flex-row align-center filters"
         v-if="(classifications && currentRoute != 'assessment') || assessmentCardsExist">
      <div class="selected-class-wrap-point" @click="handleSelectClassification(-1)">
        <p class="text" :class="{ active: currentClassification === -1 }">
          {{ $t('ALL') }}
        </p>
      </div>

      <div class="selected-class-wrap-point " @click="handleSelectClassification(classification.id)"
           v-for="(classification, i) in getGradeClassifications" :key="i">
        <p class="text" :class="{ active: currentClassification === classification.id }">
          {{ classification.value }}
        </p>
      </div>
    </div>
    <div class="items-container d-flex flex-wrap">
      <div class="col-12 col-md-6 col-lg-3" v-for="(card, index) in getGradeClasses" v-if="canDisplayCard(card)"
           :key="index">
        <div class="item-box" @click="openEntryToPreview({entry: card})">
          <div class="label" v-if="card.assessment">
                      <span class="text">
                        {{ $t('ASSESSMENT') }}
                      </span>
          </div>
          <div class="card-image">
            <img class="image" :src="card.image" alt=""/>
            <div class="card-fixed">
              <div class="top-wrap">
                <div v-if="user.isTeacher()" class="box" @click="$event.stopPropagation(); toggleInfo(card)">
                  <img class="i" src="@/assets/icons/info.svg" alt=""/>
                </div>
              </div>
              <div class="bottom-wrap">
                <div class="d-flex card-bottom">
                  <p>{{ card.title }}</p>
                  <img v-if="user.isTeacher()" class="share" src="@/assets/icons/share.svg" alt="share icon"
                       style="margin-inline-start: auto;" @click="openShareDrop($event, card)"/>
                  <div class="share-wrap" v-if="isOpenShareDrop === card.id">
                    <h2 class="share-wrap-title" @click="openShareDrop($event, card)">
                      <span>{{ $t('SHARE') }}</span>
                      <img src="@/assets/icons/share-grey.svg" class="share-grey-icon" alt="">
                    </h2>
                    <div class="wrap" @click="$event.stopPropagation()">
                      <h3 class="share-list-title">
                        <span>{{ $t('SELECT_CLASS') }}</span>
                      </h3>
                      <ul class="share-list">
                        <li class="share-item" v-for="(grade, i) in classes" :key="i">
                          <input type="checkbox" class="share-item-check" @click="addClassToShare(grade.id)">
                          <span>{{ grade.name }}</span>
                        </li>
                      </ul>
                    </div>
                    <button class="share-button" :class="{'disable': !classesToShare.length}"
                            @click="shareClasses($event, card.metadata.lesson_id)">
                             <span class="text">
                                {{ $t('SHARE') }}
                             </span>
                    </button>
                  </div>
                </div>
                <div class="sub d-flex">
                  <div class="d-flex flex-row align-center"
                       v-for="(classification, index) in getClassifications(card.classifications)">
                    <p>{{ classification.value }}</p>
                    <div class="line-h" v-if="index<card.classifications.length-1">|</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-popup-overlay" v-if="isOpenSharePopup">
      <div class="modal-popup">
        <div class="close-x" @click="isOpenSharePopup = false">
          <img class="close-icon" src="@/assets/icons/close_icon.svg" alt="close">
        </div>
        <div class="modal-popup-content">
          <p class="text">
            {{ $t('SUCCESS_SHARE') }}!
          </p>
          <router-link :to="{ name: 'tasks'}" href="" class="url">
            {{ $t('GO_TO_SHARE_TASKS') }}
          </router-link>
        </div>
        <div class="modal-popup-buttons" @click="isOpenSharePopup = false">
          <button class="close-button">
            <span>{{ $t('CLOSE') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="!loading && (!assessmentCardsExist && currentRoute == 'assessment')">
      <div class="no-tasks">
        <h2 class="no-tasks-title">{{ $t('TEACHER_EMPTY_ASSESSMENTS_MSG') }}</h2>
        <p class="no-tasks-text">{{ $t('TEACHER_EMPTY_ASSESSMENTS_MSG_2') }}</p>
      </div>
    </div>
    <Modal style="z-index: 99999" v-if="selectedCard" :currentCartDesc="selectedCard" :showDescCart="toggleInfo"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Modal from '@/components/Modal'
import Loader from '../../../components/Loader'
import { ASSESSMENT_KEY, ASSESSMENT_KEY_ARB, ASSESSMENT_VALUE, ASSESSMENT_VALUE_ARB } from '@/constants.js'

export default {
  name: 'assignments',
  data () {
    return {
      classesToShare: [],
      isOpenShareDrop: false,
      currentUser: '',
      content: [],
      classifications: [],
      primaryClassificationTitle: null,
      currentGrade: null,
      currentClassification: -1,
      loading: false,
      selectedCard: null,
      isOpenSharePopup: false
    }
  },
  components: {
    Loader,
    Modal
  },
  async mounted () {
    this.loading = true
    if (!this.selectedClass) {
      this.$store.dispatch('setSelectedClass', this.classes[0].id)
    }
    this.content = await this.user.fetchData(this.$route.name, {
      classId: this.selectedClass,
      slug: this.librarySlug,
    })
    this.content = this.buildUnitContent(this.content.node.children)
    console.log(this.content)
    this.handleSelectClassification(-1)
    this.loading = false
    const libraryData = await this.$api.getHome(
        'library/' + this.librarySlug + '/'
    )

    this.classifications = await this.$api.getHome(
        'library/' + this.librarySlug + '/classifications/'
    )

    this.primaryClassificationTitle = this.classifications.find(
        (classifications) =>
            classifications.id == libraryData.primary_classification_key
    ).title

    this.classifications = this.buildClassification(
        this.classifications,
        libraryData.primary_classification_key
    )

    this.$nextTick(() => {
      if (this.selectedClass) {
        this.currentGrade = this.$auth.user.getUserClassGrade(this.selectedClass)
      }
    })
  },
  watch: {
    currentGrade () {
      this.handleSelectClassification(-1)
    },
    async selectedClass () {
      this.loading = true
      this.content = await this.user.fetchData(this.$route.name, {
        classId: this.selectedClass,
        slug: this.librarySlug,
      })
      this.content = this.buildUnitContent(this.content.node.children)
      console.log(this.content)
      this.handleSelectClassification(-1)
      this.loading = false
    },
  },
  computed: {
    ...mapState({
      librarySlug: state => state.librarySlug,
      selectedClass: state => state.selectedClass
    }),
    currentRoute () {
      return this.$route.name
    },
    getGradeClassifications () {
      if (!this.currentGrade) {
        return []
      }
      // Get selected collection
      const grade = this.getGradeBySlug(this.currentGrade)
      let classifications = []
      const isAssessment = this.currentRoute === 'assessment'
      // Get all exist classifications in the collection that associated to the primary classification.
      grade?.classes.filter(classNode => classNode.assessment === isAssessment)
          .forEach(classNode => {
            classNode.classifications?.forEach((c) => {
              if (c.key === this.primaryClassificationTitle)
                classifications.push(c)
            })
          })

      // Remove duplicates
      classifications = [...new Set(classifications.map(JSON.stringify))].map(
          JSON.parse
      )
      return classifications
    },
    getGradeClasses () {
      if (!this.currentGrade) {
        return []
      }
      const grade = this.getGradeBySlug(this.currentGrade)
      if (this.currentClassification == -1) {
        return grade.classes
      }
      return grade.classes.filter((classNode) => {
        const classifications = classNode.classifications.map(
            (classification) => classification.id
        )
        return classifications.includes(this.currentClassification)
      })
    },
    assessmentCardsExist () {
      let assessmentCards = []
      if (this.currentRoute === 'assessment' && this.user.isTeacher) {
        assessmentCards = this.getGradeClasses.filter(card => card.assessment)
        return assessmentCards.length
      }
      return false
    },
    user () {
      return this.$auth.user
    },
    classes () {
      return this.$store.getters['classes']
    },
  },
  methods: {
    ...mapActions(['openEntryToPreview']),
    getClassifications (unit) {
      let classifications = []
      unit.classifications?.forEach(c => {
        if (c.key === this.primaryClassificationTitle)
          classifications.push(c)
      })
    },
    canDisplayCard (card) {
      //if card is content be display only at "content" tab
      //if card is assessment be dispaly only "assessment" tab and user is teacher
      if (this.currentRoute === 'assessment') {
        if (this.user.isTeacher && card.assessment) {
          return true
        }
        return false
      } else if (this.currentRoute === 'content') {
        if (card.assessment) {
          return false
        }
        return true
      }
      return false
    },
    openSharePopup () {
      this.isOpenSharePopup = true
    },
    shareClasses (event, lessonId) {
      event.stopPropagation()
      if (!this.classesToShare.length) return
      this.$store.dispatch('shareClasses', { listOfClasses: this.classesToShare, lessonId })
          .then(() => {
            this.isOpenSharePopup = true
          })
    },
    addClassToShare (classId) {
      const index = this.classesToShare.indexOf(classId)
      if (index > -1) {
        this.classesToShare.splice(index, 1)
      } else {
        this.classesToShare.push(classId)
      }
    },
    openShareDrop (event, card) {
      event.stopPropagation()
      if (this.isOpenShareDrop === card.id) {
        this.isOpenShareDrop = null
      } else {
        this.isOpenShareDrop = card.id
      }
      this.classesToShare = []
    },
    getSelectedClassification (card) {
      return card.classifications.filter((c) => c.key === 'סוג משימה')
    },
    buildClassification (node, key) {
      return node.filter((classifications) => classifications.id == key)[0]?.values
    },
    handleSelectGrade (grade) {
      this.handleSelectClassification(-1)
      this.currentGrade = grade.slug
    },
    handleSelectClassification (val) {
      this.isOpenShareDrop = null
      this.classesToShare = null
      this.currentClassification = val
    },
    getGradeBySlug (slug) {
      return this.content.filter((classNode) => classNode.slug == slug)[0]
    },
    isArb () {
      return this.$store.state.librarySlug === 'ramot-arb'
    },
    buildUnitContent (node) {
      let assessment_key = (this.isArb()) ? ASSESSMENT_KEY_ARB : ASSESSMENT_KEY
      let assessment_value = (this.isArb()) ? ASSESSMENT_VALUE_ARB : ASSESSMENT_VALUE
      console.log(node)
      return node.map((nodeItem) => {
        return {
          slug: nodeItem.data.slug,
          title: nodeItem.data.title,
          classes: nodeItem.entries.map((classNode) => {
            return {
              metadata: classNode.data.metadata,
              id: classNode.data.id,
              title: classNode.data.title,
              description: classNode.data.description,
              assessment: (classNode.data.classifications.findIndex(classification => classification['key'] == assessment_key && classification['value'] == assessment_value) !== -1) ? true : false,
              image: classNode.data.image,
              classifications: classNode.data.classifications,
              ...classNode.object,
            }
          }),
        }
      })
    },
    toggleInfo (val = null) {
      this.selectedCard = val
    },
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap');

</style>
<style lang="scss">
.content-page {
  .head-unit {
    h1 {
      font-size: 60px;
      text-align: center;
      color: #0f707b;
      @media (max-width: 959px) {
        margin-top: 80px;
        font-size: 40px;
      }
    }
  }

  .class-selected {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    line-height: 1.1;

    .select-wrap {
      display: flex;
      align-items: center;
      gap: 8px;
      background: #efefef;
      font-size: 21px;
      border-radius: 5px;
      cursor: pointer;
      @media (min-width: 600px) {
        padding: 5px 10px;
      }
      @media (max-width: 599px) {
        padding: 0 15px;
        outline: none;
      }

      .select-class {
        cursor: pointer;
      }
    }

    p {
      color: #504e4e;
      font-weight: 300;
      margin: 0 15px;
      @media (min-width: 600px) {
        font-size: 40px;
      }
      @media (max-width: 599px) {
        font-size: 35px;
      }
      @media (max-width: 499px) {
        font-size: 25px;
      }
    }
  }

  .selected-class {
    padding: 30px 0;

    .selected-class-wrap-point {
      width: fit-content;
      &:first-child{min-width: 100px; text-align: center;}
      .text {
        cursor: pointer;
        margin-block: auto;
        font-size: 24px;

        &.active {
          border-bottom: 4px solid #0f707b;
        }
      }
      &:not(:last-child){
        padding-inline: 15px;
        @media screen and (max-width: 768px) {
          border-inline-end: 1px solid #000;
        }
      }
    }
  }

  .items-container {
    .col-12,
    .col-md-6,
    .col-lg-3 {
      .item-box {
        border-radius: 10px;
        position: relative;
        cursor: pointer;

        .card-image {
          position: relative;

          img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            border-radius: 10px;
          }

          .card-fixed {
            position: absolute;
            top: 0;
            width: 100%;
            height: 98%;
            border-radius: 10px;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.01) 47%, #000000 100%);

            .top-wrap {
              height: 80%;
              position: relative;

              .box {
                width: 70px;
                height: 70px;
                border-top-left-radius: 10px;
                border-bottom-right-radius: 10px;
                background-color: #469ca4;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24.2px;
                color: wheat;
                position: absolute;
                z-index: 9;
                left: 0;
                @media (max-width: 768px) {
                  width: 60px;
                  height: 60px;
                }

                .i {
                  position: absolute;
                  left: 17%;
                  width: 42px;
                  height: 42px;
                  cursor: pointer;
                  z-index: -99;
                  @media (max-width: 768px) {
                    left: 20%;
                    width: 34px;
                    height: 30px;
                  }
                }
              }
            }

            .bottom-wrap {
              height: 20%;
              justify-content: space-between;
              align-items: center;
              padding: 0 10px;
              position: relative;

              .sub {
                border-top: 1px solid rgba(255, 255, 255, 0.722);
                @media (max-width: 959px) {
                  padding: 5px 0;
                }

                .line-h {
                  color: white;
                  padding: 0 5px;
                }

                p {
                  font-size: 23px;
                  font-weight: 300;
                  @media (max-width: 959px) {
                    font-size: 18px;
                  }
                }
              }

              p {
                font-size: 30px;
                font-weight: bold;
                line-height: 1;
                color: white;
                margin-bottom: 0;
              }

              .share {
                width: 25px;
                height: 25px;
                border-radius: 0;
                object-fit: contain;
              }

              .share-grey-icon {
                width: 20px;
                height: 35px;
                object-fit: contain;
                margin-inline-end: 8px;
              }
            }
          }
        }
      }
    }
  }

  .delete-task-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: #0000005e;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;

    &.active {
      display: flex;
    }

    .delete-task-popup-container {
      background: white;
      border-radius: 25px;
      padding: 30px;
      position: relative;

      .delete-task-popup-header {
        .close {
          position: absolute;
          left: 15px;
          top: 10px;
          display: flex;
          justify-content: flex-end;
          z-index: 99;
          padding: 10px 0;
        }
      }

      .close {
        position: absolute;
        left: 15px;
        top: 10px;
        display: flex;
        justify-content: flex-end;
        z-index: 99;
        padding: 10px 0;
      }

      h1 {
        font-weight: 300;
        padding: 15px 0;
      }

      .btns-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 0;

        .btns-option {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          width: 340px;

          .btn-delete {
            width: 153px;
            height: 40px;
            background: #0f707b;
            cursor: pointer;
            border-radius: 30px;
            color: white;
            font-size: 18px;
          }

          .btn-cancel {
            width: 153px;
            height: 40px;
            background: white;
            border: 1px solid #0f707b;
            cursor: pointer;
            border-radius: 30px;
            color: #0f707b;
            font-size: 18px;
          }
        }
      }
    }
  }

  .task-unit-container {
    height: 45vh;
    overflow: auto;
    padding-bottom: 25px;
  }

  .unit-content {
    padding: 40px 0;

    .circle-unit {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      cursor: pointer;

      img {
        @media (max-width: 959px) {
          width: 70px;
          height: 70px;
        }
        @media (max-width: 768px) {
          width: 63px;
          height: 63px;
        }
        @media (max-width: 599px) {
          width: 53px;
          height: 53px;
        }
      }

      p {
        margin: 10px 0;
        font-size: 20px;
        color: #504e4e;
        @media (max-width: 959px) {
          font-size: 17px;
        }
        @media (max-width: 768px) {
          font-size: 14px;
          padding: 0 5px;
          text-align: center;
        }
      }
    }
  }
}

.share-wrap {
  position: absolute;
  z-index: 120;
  inset-inline-end: 5px;
  min-width: 160px;
  background-color: #fff;
  border-radius: 3px;
  padding: 5px 0;
  color: #707070;
  box-shadow: 0 3px 6px #00000029;

  &-title {
    display: flex;
    justify-content: space-between;
    color: #434242;
    font-size: 22px;
    font-weight: 300;
    box-shadow: 0 3px 6px #00000029;
    padding-inline-start: 8px;
  }

  .share-list-title {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    padding-inline-end: 8px;
    margin-top: 10px;

    .angle-bracket {
      margin-inline-start: 20px;
      font-weight: bold;
      padding-top: 5px;
    }
  }

  .wrap {
    height: 120px;
    overflow: auto;
    padding-inline-start: 8px;
  }

  .share-list {
    list-style: none;
  }

  .share-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .share-item-check {
    width: 10px;
  }

  .share-button {
    width: 70px;
    height: 26px;
    border-radius: 15px;
    text-align: center;
    margin: 5px auto;
    background-color: #469ca4;
    display: block;

    .text {
      color: #fff;
      font-size: 20px;
      font-weight: 300;
    }

    &.disable {
      background-color: #dadada;
    }
  }

  /*Custom scrollbar*/
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #707070;
}

select:focus {
  outline: none !important;
}

input[type="checkbox"]:focus {
  outline: 0;
}

/* TODO: This is for assessment*/
.item-box {
  position: relative;

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 5px;
    text-align: center;
    position: absolute;
    z-index: 1;
    min-height: 50px;
    width: 90px;
    border: 1px solid #fff;
    letter-spacing: 1.5px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #2D9199;
    border-top: none;
    border-right: none;

    .text {
      color: #fff;
      font-size: 16px;
      font-family: Arimo, sans-serif;
      line-height: 1;
      .arb &{
        font-size: 18px; line-height: 1.2; font-weight: 600;
      }
    }
  }
}
.filters{
  width: 100%; overflow-y: auto; gap: 10px;
  .text{white-space: nowrap;}
  @media screen and (min-width: 769px){
    justify-content: space-between;
  }
}
</style>
