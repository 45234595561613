import Vue from 'vue'
import Vuex from 'vuex'
import User from '../models/User'
import { MUTATION_TYPES } from './types'
import helpers from '@/helpers'
import api from '@/api'
import auth from '@/auth'
import router from '../router'
import i18n from '@/i18n'
import { getLoginErrorMessage } from '../components/utilityFunctions.js'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    token: sessionStorage.getItem('token'),
    school: sessionStorage.getItem('school'),
    isPopupLoading: false,
    isLoggedIn: false,
    librarySlug: null,
    feedbackList: [],
    selectedFeedbackId: null ,
    selectedClass: null,
    videos: null,
    logoUrl: null
  },
  mutations: {
    SET_SELECTED_FEEDBACK_ID (state, value) {
      state.selectedFeedbackId = value
    },
    SET_LOGO_URL (state, logo) {
      state.logoUrl = logo
    },
    SET_SELECTED_CLASS (state, value) {
      state.selectedClass = value
    },
    SET_VIDEOS (state, videos) {
      state.videos = videos
    },
    SET_FEEDBACK (state, value) {
      state.feedbackList = value
    },
    REMOVE_FEEDBACK (state, index) {
      state.feedbackList.splice(index, 1)
    },
    SET_POPUP_LOADING (state, value) {
      state.isPopupLoading = value
    },
    [MUTATION_TYPES.SET_USER] (state, payload) {
      let user = payload.user
      let token = payload.token

      if (user) {
        if (!(user instanceof User)) {
          user = new User({
            ...payload.user,
          })
        }

        state.user = user
      }

      if (token) {
        sessionStorage.setItem('token', token)

        state.token = token
      }
    },

    [MUTATION_TYPES.LOGOUT_USER] (state, loginErrorMessage = false) {
      state.user = null
      state.token = null
      state.school = null
      state.isLoggedIn = false

      sessionStorage.removeItem('token')
      sessionStorage.removeItem('school')

      auth.instance().logout(loginErrorMessage)
    },

    [MUTATION_TYPES.SET_SCHOOL] (state, index) {
      state.school = index
      sessionStorage.setItem('school', index)
      this.dispatch('ramotUsersCounter', state.user.schools[index].id)
    },

    [MUTATION_TYPES.SET_LIBRARY_SLUG] (state, librarySlug) {
      state.librarySlug = librarySlug
    },

    [MUTATION_TYPES.UNSET_USER] (state) {
      state.user = new User({
        ...INIT_USER,
      })
    },

    [MUTATION_TYPES.SET_LOGGEDIN] (state, loggedIn) {
      state.isLoggedIn = loggedIn
    },
  },
  actions: {
    ramotUsersCounter( context, semelId) {
      return api.instance().put('/ramot-users-counter/' + semelId + '/')
    },
    goToFeedback ({ commit, state, dispatch }, {feedback, index}) {
      let url = null
      const baseUrl = helpers.env('LEGACY_CLASSE_HOST')
      const lang = state.librarySlug === 'ramot-heb' ? 'he' : 'arb'
      if (state.user.isTeacher()) {
        url = baseUrl + '/teacher/#!/' + lang + '/session/containerReport/' + feedback.unitid + '/' + feedback.lid + '/gradeLessonReport/'
      } else {
        url = baseUrl + '/teacher/#!/' + lang + '/session/' + feedback.unitid + '/playLesson/' + feedback.lid + '/'
        dispatch('setSelectedFeedbackId', feedback.feedback_id)
      }
      dispatch('openEntryInNewWindow', url)
      dispatch('removeFeedback',index)
    },
    removeFeedback(context, index) {
      context.commit('REMOVE_FEEDBACK', index)
    },
    resetPassword (context, value) {
      auth.instance().resetPassword(value)
    },
    changePassword (context, value) {
      auth.instance().changePassword(value)
    },
    setSelectedFeedbackId (context, value) {
      context.commit('SET_SELECTED_FEEDBACK_ID', value)
    },
    setSelectedClass (context, value) {
      context.commit('SET_SELECTED_CLASS', value)
    },
    setLogoUrl(context, logo) {
      context.commit('SET_LOGO_URL', logo)
    },
    setLang (context, slug) {
      const lang = slug === 'ramot-arb' ? 'ar' : 'he'
      i18n.locale = lang
    },
    getFeedback (context) {
      return api.instance().get('/feedback/new-feedback/')
        .then((response) => {
          context.commit('SET_FEEDBACK', response)
        })
    },
    shareClasses (context, { listOfClasses, lessonId }) {
      return api.instance().post('/lesson/' + lessonId + '/share-ramot-lesson/', { class_ids: listOfClasses })
    },
    setPopupLoading (context, value) {
      context.commit('SET_POPUP_LOADING', value)
    },
    openEntryToPreview: function (context, { entry, isContentUnit = false }) {
      // Checks if the user is recognized,
      // If not, enter with quick-link
      if (context.state.isLoggedIn) {
        api.instance().post('/units/' + entry.metadata.unit_id + '/join-for-preview/').then(() => {
          let url = null
          const baseUrl = helpers.env('LEGACY_CLASSE_HOST')
          const lang = context.state.librarySlug === 'ramot-heb' ? 'he' : 'arb'
          if (entry.metadata.lesson_id) {
            url = baseUrl + '/teacher/#!/' + lang + '/session/' + entry.metadata.unit_id + '/playLesson/' + entry.metadata.lesson_id + '/'
          } else {
            url = baseUrl + '/teacher/#!/' + lang + '/session/' + entry.metadata.unit_id + '/lessonsTable/'
          }
          this.dispatch('openEntryInNewWindow', url)
        })
      } else {
        // Check if we need to restrict content to login. If yes -
        // restrict access to the "content units" if user is not authenticated
        // and popup the login screen instead.
        const block = helpers.env('RESTRICT_CONTENT_TO_AUTHENTICATED_USERS') === 'true'
        if (block && isContentUnit) {
          location.href = helpers.getIdmURL()
        } else {
          this.dispatch('openEntryInNewWindow', entry.url)
        }
      }

    },
    getVideos (context) {
      // TODO: Replace it with a librarySlug when arb-videos will be exist
      api.instance().getHome("collections/" + "ramot-heb-videos/").then((response) => {
        const videos = response.entries.map(entry => {
          return {
            title: entry.data.title,
            url: entry.object.url
          }
        })
        context.commit('SET_VIDEOS', videos)
      })
    },
    openEntryInNewWindow ({dispatch, state}, url) {
      if (state.token) {
        const jwt = helpers.decodeJWT(state.token)
        const exp = jwt.exp || Date.now
        const diff = Date.now() - exp * 1000
        if (!exp || diff > 0) {
          console.log('current diff: ' + diff)
          alert('session expired')
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('school')
          location.reload()
          return
        }
      }
      const width = window.innerWidth
      const height = window.innerHeight
      let params = '?hideLogout=true&hideBackUnit=true'
      if(url.indexOf('playLesson')!==-1){
        params = '?hideLogout=true&hideBackLesson=true'
      }
      if(state.selectedFeedbackId){
        params+='&feedback='+state.selectedFeedbackId
        dispatch('setSelectedFeedbackId',null)
      }
      url = url + params
      window.open(url, '_blank', 'width=' + width + ',height=' + height
        + ', top=' + ((window.outerHeight - height) / 2))
    },
    async fetchAuthenticatedUser ({ commit, state }, getArbSchool = false) {
      let schoolId = null
      if (getArbSchool) {
        schoolId = helpers.env('ARB_SCHOOL_ID')
      } else {
        schoolId = helpers.env('HE_SCHOOL_ID')
      }
      if (state.token) {
        try {
          const resp = await api
            .instance()
            .get('/my-profile/classes/?school_id=' + schoolId)
          await commit(MUTATION_TYPES.SET_USER, {
            user: resp,
            token: state.token,
          })

          // If the user have permission -
          // Go to the internal pages.
          // If not - try to connect to arb library,
          // If it's failed too, we will get an error page

          if (!resp.schools.length) {
            if (!getArbSchool) {
              await this.dispatch('fetchAuthenticatedUser', true)
            } else {
              const schoolData = await auth.instance().getUserSchools()
              const loginErrorMessage = getLoginErrorMessage(schoolData)
              commit(MUTATION_TYPES.LOGOUT_USER, loginErrorMessage)
              return false
            }
          } else {
            const slug = getArbSchool? 'ramot-arb': 'ramot-heb'
            if (getArbSchool) {
              await commit(MUTATION_TYPES.SET_LIBRARY_SLUG, 'ramot-arb')
              this.dispatch('setLang', 'ramot-arb')
            }
            commit(MUTATION_TYPES.SET_LOGGEDIN, true)
            if (resp.schools.length > 1) {
              if (!state.school) {
                router.replace({ name: 'select_school', params: { slug } })
              } else {
                router.replace({ name: 'dashboard', params: { slug } })
              }
            } else {
                commit(MUTATION_TYPES.SET_SCHOOL, 0)
                router.replace({ name: 'dashboard', params: { slug } })
            }
          }

          return true
        } catch (e) {
          commit(MUTATION_TYPES.LOGOUT_USER, 'general-error')
          return false
        }
      } else {
        return false
      }
    },
  },
  modules: {},
  getters: {
    role: (state) => state.user?.role,
    token: (state) => state.token,
    school: (state) => {
      if (!state.user) {
        return null
      }

      if (state.user.isStudent()) {
        return state.user.schools[0]
      }

      return state.user?.schools[state.school]
    },
    schoolIndex: (state) => state.school,
    classes: (state, getters) => {
      if (!state.user) {
        return null
      }

      if (state.user.isStudent()) {
        return state.user.classes
      }

      return state.user.classes.filter(
        (classItem) => classItem.school?.id == getters.school?.id
      )
    },
    user: (state) => state.user,
    isLoggedIn: (state) => state.isLoggedIn,
    getApprovalIcon: (state) => {
      let icon = null
        if (state.librarySlug === 'ramot-arb') {
          icon = 'scope_arb_2.svg'
        } else {
          icon = 'scope.svg'
        }
        return require('@/assets/icons/' + icon)
    }
  },
})
